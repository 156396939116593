/************* ProjectModal.js *************/
/* Color Variables */
:root {
    --modal-bg: #1b1b1f; /* Rich Jet Black */
    --header-bg: #2c2f36; /* Slate Gray */
    --gallery-bg: #24262b; /* Gunmetal Gray */
}

/* Overlay covering the entire screen */
#modal .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
}

/* Modal Container */
#modal .custom-modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #fff;
    width: 80%;
    margin-top: 11vh;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
    animation: fadeIn 0.5s ease-in-out;
}

/* Header */
#modal .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 20px;
    background: var(--header-bg);
    border-bottom: 1px solid #444;
    margin-bottom: 10px;
}

#modal .custom-modal-header h2 {
    margin: 0;
    text-align: left;
}

#modal .close-button {
    font-size: 2rem;
    cursor: pointer;
    color: #aaa;
}

#modal .close-button:hover {
    color: #fff;
}

/* Modal Content Layout */
#modal .custom-modal-content {
    background: var(--modal-bg);
    border-radius: 20px;
    display: flex;
    padding: 20px;
    gap: 20px;
    margin-bottom: 10px;
}

#modal .custom-modal-description {
    flex: 1;
    text-align: left;
    max-height: 40vh;
    overflow: auto;
    border-radius: 10px;
}

#modal .description {
    width: 100%;
    text-align: left;
}
#modal .description p {
    width: 100%;
    margin: 0;
    text-align: left;
}

#modal .custom-modal-image img {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    object-fit: cover;
}

/* Buttons */
#modal .custom-modal-buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

#modal .custom-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 8px;
    font-weight: bold;
    transition: all 0.3s ease;
    font-size: 14px;
}

#modal .custom-button.primary {
    background-color: #4a2fbd;
    color: #fff;
}

#modal .custom-button.secondary {
    background-color: #333;
    color: #fff;
}

#modal .custom-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

#modal .button-icon {
    font-size: 1.2rem;
}

/* Inline Gallery at the End */
#modal .custom-inline-gallery {
    border-radius: 10px;
    padding: 20px;
    border-top: 1px solid #444;
    background: var(--gallery-bg);
}

#modal .gallery-images {
    display: flex;
    gap: 15px;
    overflow-x: auto;
}

#modal .gallery-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    padding: 5px;
}

#modal .gallery-image:hover {
    transform: scale(1.05);
}

/* Enlarged Image Overlay */
#modal .image-enlarge-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
}

#modal .enlarged-image {
    max-width: 80%;
    max-height: 80%;
    margin-top: 5vw;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
    animation: fadeIn 0.3s ease-in-out;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Gallery Navigation Arrows */
#modal .gallery-nav {
    position: absolute;
    top: 50%;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
    transform: translateY(-50%);
    user-select: none;
    z-index: 1200;
}

#modal .gallery-nav:hover {
    color: #aaa;
}

#modal .gallery-nav.left {
    left: 5%;
}

#modal .gallery-nav.right {
    right: 5%;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
    #modal .custom-modal-content {
        flex-direction: column; /* Stack items vertically */
    }

    #modal .custom-modal-description {
        order: 2; /* Ensure the description appears below the image */
        width: 100%; /* Use full width */
        margin-top: 10px; /* Add some spacing */
    }

    #modal .custom-modal-image {
        order: 1; /* Ensure the image appears above the description */
        width: 100%; /* Use full width */
        text-align: center; /* Center the image for better alignment */
    }

    #modal .custom-modal-image img {
        max-width: 100%; /* Ensure the image scales properly */
        height: auto; /* Maintain aspect ratio */
    }
    #modal .custom-modal-header h2 {
        font-size: 30px;
    }
}
