/************ Projects Css ************/
.project {
    padding: 80px 0;
    position: relative;
    background-color: black;
}

.project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

.project p {
    color: #b8b8b8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}

.project li {
    color: #b8b8b8;
    font-size: 18px;
    letter-spacing: 0.8px;
    margin: 14px auto 30px auto;
    text-align: left;
    line-height: 1em;
}

.project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}

.project .nav.nav-pills .nav-item {
    width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.nav-link#projects-tabs-tab-first {
    border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-third {
    border-radius: 0 55px 55px 0;
}

.proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
}

/* start with covered project cards */
/* 
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 0; 
  opacity: 0;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  width: 100%;
  color: white;
}

.proj-imgbx:hover .proj-txtx {
  opacity: 0;
  transform: translate(-50%, -70%); 
} */

.proj-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
    height: 100%;
}

.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
}

.proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}

.proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}

@media (max-width: 1280px) {
    .project .nav.nav-pills {
        flex-direction: column;
    }

    .project .nav.nav-pills .nav-item {
        width: 100%;
    }

    .project .nav.nav-pills .nav-link {
        border-radius: 0;
        border: none;
    }

    .nav-link#projects-tabs-tab-first {
        border-radius: 0;
        border: none;
    }

    .nav-link#projects-tabs-tab-second {
        border: none;
    }

    .nav-link#projects-tabs-tab-third {
        border-radius: 0;
        border: none;
    }
}
