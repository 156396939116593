/*********** GraphingCalc.c *************/
/* Container Styling */
#g-calc .container {
    max-height: 80vh;
    margin-top: 50px;
    text-align: center;
    color: #e0e0e0; /* Light text color */
}

/* Input Area Styling */
#g-calc .input-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
}
/* Input Wrapper to Align Input and Button */
#g-calc .input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between input and button */
    width: 60%; /* Same width as input */
}

/* Update Input Field Styling */
#g-calc .input {
    padding: 10px;
    flex: 1; /* Take up remaining space */
    border: 1px solid #444;
    border-radius: 4px;
    background: #2c2c2c;
    color: #e0e0e0;
}

/* Update Button Styling */
#g-calc .button {
    padding: 10px 20px;
    background-color: #6a0dad;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Prevent button from shrinking */
}

#g-calc .button:hover {
    background-color: #4b0082; /* Darker purple on hover */
}

/* Canvas Styling: Make it Square */
#g-calc .canvas {
    width: 50vh; /* Square dimensions based on height */
    height: 50vh;
    display: block;
    margin: 0 auto;
    border: 1px solid #444;
    background: #1e1e1e;
}

/* Media Query: Full-Screen Layout for Phones and Smaller Screens */
@media (max-width: 660px) {
    #g-calc .container {
        max-width: 100%; /* Use full screen width */
        margin: 0;
        padding: 10px; /* Reduce padding */
        border-radius: 0; /* Remove border radius for full screen effect */
    }

    #g-calc .input-wrapper {
        flex-direction: column; /* Stack input and button vertically on small screens */
        width: 100%;
    }

    #g-calc .input,
    #g-calc .button {
        width: 100%;
    }

    #g-calc .canvas {
        width: 80vw; /* Full width */
        height: 80vw; /* Keep canvas square */
    }
}
