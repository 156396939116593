/* General container styles */
#qr-gen .container {
    color: #fff;
    text-align: center;
    padding: 35px;
    overflow: auto;
    font-family: Arial, sans-serif;
}

/* QR Code generator heading */
#qr-gen .heading {
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 3.5em;
}

/* Input container styling */
#qr-gen .input-container {
    margin: 20px auto;
    width: 90%;
    max-width: 400px;
}

/* Input field styling */
#qr-gen .text-input,
#qr-gen .qr-type-select,
#qr-gen .download-select {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    margin: 10px 0;
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 5px;
    outline: none;
    text-align: center;
}

/* Placeholder color */
#qr-gen .text-input::placeholder {
    color: #ccc;
}

/* Label styling */
#qr-gen .label {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
}

/* Button styling */
#qr-gen .button {
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 16px;
    background-color: #6a0dad; /* Purple button */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    transition: background-color 0.3s ease; /* Smooth hover transition */
}

#qr-gen .button:hover {
    background-color: #4b0082; /* Darker shade for hover */
}

#qr-gen .button:disabled {
    background-color: #555;
    cursor: not-allowed;
}

/* QR Code styling */
#qr-gen .qr-code {
    margin: 20px auto;
    width: 300px;
    height: 300px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
}

/* Progress bar container (optional if needed) */
#qr-gen .progressBar {
    width: 100%;
    max-width: 400px;
    background-color: #333;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px auto;
}

/* Progress bar fill */
#qr-gen .progress {
    height: 100%;
    background-color: #000;
    transition: width 0.3s ease-in-out;
}

/* Checkbox container */
#qr-gen .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    width: 100%;
    max-width: 400px;
}

/* Styled checkbox */
#qr-gen .styled-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid #fff;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

#qr-gen .styled-checkbox:checked {
    background-color: #6a0dad; /* Purple background when checked */
    border-color: #4b0082; /* Darker purple border when checked */
}

#qr-gen .styled-checkbox:focus {
    outline: none;
    box-shadow: 0 0 3px 2px rgba(255, 255, 255, 0.5);
}

/* Checkbox label styling */
#qr-gen .label {
    font-size: 16px;
    color: #fff;
    text-align: left;
    margin: 0;
}
