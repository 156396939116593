#sprint-manager .sprint-manager {
    /* Dark mode background */
    border-radius: 15px;
    max-width: 95vw;
    margin: 35px auto;
    padding: 10px;
    font-size: 12px;
    color: #e0e0e0;
    border-radius: 10px;
}

#sprint-manager .add-task-btn {
    margin: 10px 0;
    background-color: #6a0dad;
    /* Purple color */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: "background-color 0.3s ease";
}

#sprint-manager .add-task-btn:hover {
    background-color: #4b0082;
    /* Darker purple on hover */
}

#sprint-manager .board {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#sprint-manager .section {
    padding: 15px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #1e1e1e;
}

#sprint-manager .section h2 {
    margin-top: 0;
    font-size: 14px;
    color: #fff;
}

#sprint-manager .issue {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #2c2c2c;
    font-size: 12px;
}

#sprint-manager .issue h3 {
    margin-top: 0;
    font-size: 12px;
    color: #fff;
}

#sprint-manager .issue p {
    font-size: 10px;
    margin: 5px 0;
    color: #a0a0a0;
}

#sprint-manager button {
    background-color: #6a0dad;
    /* Purple color */
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
}

#sprint-manager button:hover {
    transition: background-color 0.3s ease;
    /* Smooth hover transition */
    background-color: #4b0082;
    /* Darker purple on hover */
}

@media (min-width: 768px) {
    #sprint-manager .board {
        flex-direction: row;
        justify-content: space-between;
    }

    #sprint-manager .section {
        flex-basis: 30%;
    }
}
