/* General container styles */
#img-conv .container {
    color: #fff;
    text-align: center;
    padding: 35px;
    overflow: auto;
}

/* Drop zone styling */
#img-conv .dropZone {
    border: 2px dashed #fff;
    padding: 20px;
    margin: 20px auto;
    position: relative;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
}

/* Add image icon styling */
#img-conv .addImageIcon {
    font-size: 48px;
    color: #fff;
    cursor: pointer;
}

/* Text above plus sign styling */
#img-conv .textAbovePlus {
    font-size: 16px;
    margin-bottom: 10px;
}

/* Label styling */
#img-conv .label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
}

/* Select dropdown styling */
#img-conv .select {
    padding: 10px;
    margin: 10px auto;
    font-size: 16px;
    width: 90%;
    max-width: 400px;
}

/* Button styling */
#img-conv .button {
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    background-color: #6a0dad; /* Purple button */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 90%;
    max-width: 400px;
    transition: background-color 0.3s ease; /* Smooth hover transition */
}

#img-conv .button:hover {
    background-color: #4b0082; /* Darker shade for hover */
}

#img-conv .button:disabled {
    background-color: #555;
    cursor: not-allowed;
}

/* Progress bar container */
#img-conv .progressBar {
    width: 100%;
    max-width: 400px;
    background-color: #333;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px auto;
}

/* Progress bar fill */
#img-conv .progress {
    height: 100%;
    background-color: #000;
    transition: width 0.3s ease-in-out;
}
